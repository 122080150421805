import { useEffect, useState } from 'react'

import './infoBanner.css'

import { run } from '../client'
import { AiFillPropertySafety } from 'react-icons/ai'

function InfoBanner(props){

    const [showBanner, setShowBanner] = useState(false)

    useEffect(() => {
        const value = localStorage.getItem('showBanner')
        setShowBanner(value === null)
    }, [])

    async function subscribe(e){
        e.preventDefault()
        await run()
        hide()
        props.onClose()
    }

    function hide(){
        setShowBanner(false)
        localStorage.setItem('showBanner', false)
    }

    return (
        showBanner
        ?
        <div className="info_banner">
            <span>
                🔔 Get notified when new tracks are released! 🔔
                <br/>
                <a href="#" onClick={subscribe}>Click here to Subscribe</a> <a className="hide_banner" href="#" onClick={hide}>Don't show again</a>
            </span>
        </div>
        :
        null
    )
}

export default InfoBanner