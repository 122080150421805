import { SUBSCRIBE } from "./API";

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const publicVapidKey = "BA6ucQirWiTTKFJZTVNm2cB1OzFAlN7IXWxNekyzcjnZ51FbGck-WOay4FXYl3RxivCKCOeZdhKHNmnBUh8Geyw"

// if ('serviceWorker' in navigator) {
//   console.log('Registering service worker');

//   run().catch(error => console.error(error));
// }

async function _unsubscribe(){
    const registration = await navigator.serviceWorker.register('/service-worker.js', { scope: '/' });
    console.log('Registered service worker');

    console.log('Registering push');
    const sub = await registration.pushManager.getSubscription()
    await sub.unsubscribe()
    await fetch(`${SUBSCRIBE}?unsubscribe=true`, {
        method: 'POST',
        body: JSON.stringify(sub),
        headers: {
            'content-type': 'application/json'
        }
    })

}

async function checkSub(){
    const registration = await navigator.serviceWorker.register('/service-worker.js', { scope: '/' });
    console.log('Registered service worker');

    console.log('Registering push');
    const hasSub = await registration.pushManager.getSubscription()
    
    return hasSub !== null
}

async function _run() {
  console.log('Registering service worker');
  const registration = await navigator.serviceWorker.register('/service-worker.js', { scope: '/' });
  console.log('Registered service worker');

  console.log('Registering push');
  const hasSub = await registration.pushManager.getSubscription()
  if(hasSub){
      return
  }

  
  const subscription = await registration.pushManager.
    subscribe({
      userVisibleOnly: true,
      // The `urlBase64ToUint8Array()` function is the same as in
      // https://www.npmjs.com/package/web-push#using-vapid-key-for-applicationserverkey
      applicationServerKey: publicVapidKey
    });
  console.log('Registered push');

  console.log('Sending push');
  await fetch(SUBSCRIBE, {
    method: 'POST',
    body: JSON.stringify(subscription),
    headers: {
      'content-type': 'application/json'
    }
  });
  console.log('Sent push');
}

export const run = _run

export const hasSub = checkSub

export const unsubscribe = _unsubscribe