// export const TRACKLIST_API = "http://localhost:7071/api/GetTracks"
export const TRACKLIST_API = "https://josesanchezapi.azurewebsites.net/api/GetTracks"

// export const STREAM_API = "http://localhost:7071/api/CountStream"
export const STREAM_API = "https://josesanchezapi.azurewebsites.net/api/CountStream"

// export const UPLOAD_API = "http://localhost:7071/api/Upload"
export const UPLOAD_API = "https://josesanchezapi.azurewebsites.net/api/Upload"

// export const SUBSCRIBE = "http://localhost:7071/api/Subscribe"
export const SUBSCRIBE = "https://josesanchezapi.azurewebsites.net/api/Subscribe"

export const PUBLIC_TOKEN = "josehugo"

const KEY = "M8c7gML+P64Th2g7"

const _HEADERS = new Headers()
_HEADERS.append('AKEY', KEY)
export const HEADERS = _HEADERS