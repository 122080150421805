async function share(data){
    const fallback = data? `whatsapp://send?text=Jose Sanchez Tracks https://josesanchez.simae.dev/#/trackid:${data}` : 'whatsapp://send?text=Jose Sanchez Tracks https://josesanchez.simae.dev'
    if(navigator.share){
        try {
            await navigator.share({
                title: 'Jose Sanchez',
                text: '',
                url: data? `https://josesanchez.simae.dev/#/trackid:${data}` : 'https://josesanchez.simae.dev'
            })    
        } catch (error) {
            // window.open(fallback, '_blank')
        }
    }else{
        window.open(fallback, '_blank')
    }
}

export default share