import { useState, useEffect, useRef } from 'react'
import { HEADERS, PUBLIC_TOKEN, UPLOAD_API } from '../API'
import Overlay from '../Overlay'

import './upload.css'

import { BsUpload } from 'react-icons/bs'
import Loader from './../Loader'

function Upload(props){

    const [title, setTitle] = useState("")

    const [image, setImage] = useState(null)
    
    const [track, setTrack] = useState(null)

    const [duration, setDuration] = useState(0)

    const [filename, setFilename] = useState("")

    const [token, setToken] = useState("")

    const [uploadSuccess, setUploadSuccess] = useState(null)

    const date = new Date(), day = date.getDate(), month = date.getMonth()+1
    // useState(`${day<10? "0"+day:day}.${month < 10? "0"+month : month}.${date.getFullYear()}`)
    const [release, setRelease] = useState(date.toISOString().substr(0,10))

    const [loading, setLoading] = useState(false)

    const imgInputRef = useRef()

    const audioInputRef = useRef()

    const audioRef = useRef()

    const [showForm, setShowForm] = useState(false)

    const [publicToken, setPublicToken] = useState("")

    async function readFile(file){
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        return new Promise((resolve, reject) => {
            fileReader.onload = e => {
                resolve(fileReader.result.split(';base64,')[1])
            }
        })
        
    }

    async function upload(e){
        e.preventDefault()
        if(track){
            setLoading(true)
            setUploadSuccess(null)
            const trackBase64 = await readFile(track)
            
            const img = image? await readFile(image) : null

            const body = {
                title: title,
                filename: filename,
                duration: duration,
                mp3: trackBase64,
                img: img,
                release: new Date(release).getTime(),
                token: token
            }

            try {
                const res = await fetch(UPLOAD_API, {
                    headers: HEADERS,
                    method: 'POST',
                    body: JSON.stringify(body)
                })
                setUploadSuccess(res.status === 200)
            } catch (error) {
                console.log(error)
                setUploadSuccess(false)
            }finally{
                setLoading(false)
            }

        }
    }
    
    function onTrackChange(e){
        setTrack(e.target.files[0])
    }

    function onImageChange(e){
        setImage(e.target.files[0])
    }

    useEffect(() => {
        async function load (){
            if(track){
                const objURL = window.URL.createObjectURL(track)
                audioRef.current.src = objURL
                await audioRef.current.load()
                setTimeout(_ => {
                    setDuration(audioRef.current.duration)
                    if(!filename){
                        setFilename(track.name)
                    }

                    if(!title){
                        let _title = track.name.split(".")[0]
                        _title = _title.replace("-", " ")
                        _title = _title.split(" ")
                        _title.forEach((t, i) => {
                            _title[i] = `${t[0].toUpperCase()}${t.substr(1)}`
                        })
                        _title = _title.join(" ")
                        setTitle(_title)
                    }

                }, 500)
            }
        }

        load()
        
    }, [track])

    function checkToken(){
        if(publicToken === PUBLIC_TOKEN){
            setShowForm(true)
            setUploadSuccess(null)
        }else{
            setUploadSuccess(false)
        }
    }

    console.log(image)

    return (
        <Overlay onClose={props.onClose} track={{ title: "Upload" }}>
            {/* <h1>Upload</h1> */}
            <div className={uploadSuccess === false? "upload err" : "upload"} >
                {showForm
                ?
                <form onSubmit={upload}>
                    <fieldset>
                        <label>Titel</label>
                        <input type="text" name="title" value={title} onChange={e => setTitle(e.target.value)} required/>
                    </fieldset>
                    <fieldset>
                        <label>Coverimage</label>
                        <input ref={ref => imgInputRef.current = ref} name="image" type="file" accept=".png" onChange={onImageChange}/>
                        <img src={image? URL.createObjectURL(image) : '/images/placeholder.png'} style={{ width: "150px", height: "150px", display: "block", margin: "1rem auto" }} />
                        <button type="button" onClick={() => imgInputRef.current.click()}>Coverimage</button>
                    </fieldset>
                    <fieldset>
                        <label>Track</label>
                        <input ref={ref => audioInputRef.current = ref} name="audio" type="file" accept=".mp3" onChange={onTrackChange} required/>
                        <button type="button" className="ico" onClick={() => audioInputRef.current.click()}><BsUpload size={30}/></button>
                        {filename}
                    </fieldset>
                    <fieldset>
                        <label>Filename</label>
                        <input type="text" value={filename} name="filename" onChange={e => setFilename(e.target.value)}  required/>
                    </fieldset>
                    <fieldset>
                        <label>Dauer</label>
                        <input type="text" value={duration} name="duration" onChange={e => setDuration(e.target.value)} disabled/>
                    </fieldset>
                    <fieldset>
                        <label>Releasedate</label>
                        <input type="date" value={release} name="releasedate" onChange={e => setRelease(e.target.value)} required/>
                    </fieldset>
                    <fieldset>
                        <label>Uploadtoken</label>
                        <input type="password" value={token} name="token" onChange={e => setToken(e.target.value)} required/>
                    </fieldset>
                    <fieldset>
                        <button type="submit">{uploadSuccess === null? loading? <Loader /> : "Upload" : uploadSuccess? "Success!" : "Error"}</button>
                        {uploadSuccess && <button type="button" style={{marginLeft: "1rem"}} onClick={() => window.location.reload()}>close</button>}
                    </fieldset>
                    
                </form>
                
                :
                <div>
                    <fieldset>
                        <label>Public token</label>
                        <input type="password" value={publicToken} onChange={e => setPublicToken(e.target.value)}/>
                    </fieldset>
                    <fieldset>
                        <button type="button" onClick={checkToken}>{uploadSuccess === false? "Invalid" : "Submit"}</button>
                    </fieldset>
                </div>
                }

                <audio ref={ref => audioRef.current = ref}>
                </audio>
            </div>
        </Overlay>
    )
}

export default Upload