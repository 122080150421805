import React, { useState, useRef, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

import { BsPlayFill, BsPauseFill, BsArrowLeftShort, BsArrowRightShort, BsDownload, BsTrash, BsUpload } from 'react-icons/bs';
import { FaShareAlt, FaGithub, FaEnvelope, FaList, FaBell, FaBellSlash, FaChromecast } from 'react-icons/fa'

import { IoIosArrowBack, IoIosArrowForward, IoIosArrowDown, IoIosRepeat, IoIosShuffle, IoIosStats } from 'react-icons/io'

import { AiOutlineLoading, AiOutlineLoading3Quarters, AiOutlineReload } from 'react-icons/ai'

// import tracklist from './tracklist.json'

import * as Sentry from "@sentry/react"

import share from './Share'
import Overlay from './Overlay';

import { HEADERS, STREAM_API, TRACKLIST_API } from './API';
import Upload from './Upload';
import { run, hasSub, unsubscribe } from './client';
import InfoBanner from './InfoBanner';
import Loader from './Loader';

const artist = "Jose Sanchez"

const placeholderImage = "/images/placeholder.png"

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

var reconnectToChromecastSession

function App() {

  const forceUpdate = useForceUpdate()

  const [newReleases, setNewReleases] = useState([])

  const isInitial = useRef(true)

  const showLoadError = useRef(false)

  const [trackPlaying, setTrackPlaying] = useState(false)

  const [currentTrack, setCurrentTrack] = useState(null)
  
  const [overlayTrack, setOverlayTrack] = useState(null)

  const [loopAudio, setLoopAudio] = useState(false)

  const [useShuffle, setUseShuffle] = useState(false)

  const shuffleRef = useRef(false)

  const historyRef = useRef([])
  
  const [showAll, setShowAll] = useState(false)

  const [showError, setShowError] = useState(false)
  
  const [showOverlay, setShowOverlay] = useState(false)
  
  const [showInfoOverlay, setShowInfoOverlay] = useState(false)
  
  const [showTrackOverlay, setShowTrackOverlay] = useState(false)

  const [showUpload, setShowUpload] = useState(false)

  const [showQueue, setShowQueue] = useState(false)

  const [useAutoPlay, setUseAutoplay] = useState(true)

  const [hasLoaded, setHasLoaded] = useState(false)

  const [playBackRate, setPlayBackRate] = useState(1)

  const autoplayRef = useRef(true)

  const audioRef = useRef()

  const progressRef = useRef()

  const lastTrack = useRef(null)

  const timeoutRef = useRef()

  const rangeRef = useRef()

  const timeRef = useRef()

  const coverRef = useRef()

  const loadedTimeRef = useRef()

  const queueRef = useRef([])

  const [tracklist, setTracklist] = useState([])

  const [showSpinCover, setShowSpinCover] = useState(true)

  const streamCountRef = useRef({})

  const [showReload, setShowReload] = useState(false)

  const [hasSubscribed, setHasSub] = useState(false)

  const keyPressedRef = useRef(false)

  const [trackLoading, setTrackLoading] = useState(false)

  const titleElement = useRef()

  const [subLoading, setSubLoading] = useState(true)
  const chromecast = window.cjs

  const [chromecastConnected, setChromecastConnected] = useState(chromecast.connected)

  const userInitiated = useRef(false)

  


  async function checkSubscription(){
    const subscribed = await hasSub()
    setSubLoading(false)
    setHasSub(subscribed)
  }

  useEffect(() => {
    if(chromecast){
      function onPause(){
        setTrackPlaying(false)
      }

      function onEnd(){
        setTrackPlaying(false)
        if(!userInitiated.current){
          autoPlay(currentTrack, autoplayRef.current)
        }
      }

      function onDisconnect(){
        setChromecastConnected(false)
        setTrackPlaying(false)
      }

      function onUpdate(){
        audioRef.current.currentTime = chromecast.time
      }

      function onPlay(){
        setTrackPlaying(true)
        audioRef.current.currentTime = chromecast.time
      }

      function onConnect(){
        setTrackPlaying(chromecast.state === "playing")
        setChromecastConnected(true)
      }

      chromecast.on("available", forceUpdate)
      chromecast.on("connect", onConnect)

      chromecast.on('disconnect', onDisconnect)
      chromecast.on('end', onEnd)
      chromecast.on('pause', onPause)
      chromecast.on('timeupdate', onUpdate)
      chromecast.on('playing', onPlay)
    }

    return function cleanup(){
      chromecast.off('disconnect')
      chromecast.off('end')
      chromecast.off('pause')
      chromecast.off('timeupdate')
      chromecast.off('playing')
      chromecast.off('available')
      chromecast.off('connect')

    }
  }, [currentTrack])

  useEffect(() => {
    async function fetchTracklist(){
      try {
        const res = await fetch(TRACKLIST_API, { cache: 'no-cache', headers: HEADERS })
        const json = await res.json()
        setTracklist(json)
        const newReleases = json.filter(track => track.release >= (Date.now() - 60*60*1000*24*14)).sort((a,b) => b.release - a.release)
        setNewReleases(newReleases)
      } catch (error) {
        setShowError(true)
        setShowReload(true)
        Sentry.captureMessage('failed to fetch tracklist')
      }
    }

    fetchTracklist()


    checkSubscription()

    const interval = setInterval(() => {
      document.querySelectorAll('.track_duration').forEach(el => el.classList.toggle('hidden'))
      document.querySelectorAll('.streams').forEach(el => el.classList.toggle('hidden'))
    }, 3500)

    return function cleanup(){
      if(interval){
        clearInterval(interval)
      }
    }

  }, [])

  useEffect(() => {
    function onKeyPress(e){
      e.preventDefault()
      if(e.keyCode === 32){ // space
        keyPressedRef.current = true
      }
    }

    function onKeyUp(e){
      if(e.keyCode === 32 && keyPressedRef.current){
        keyPressedRef.current = false
        toggleTrack()
      }
    }

    if(!showUpload){
      document.addEventListener('keypress', onKeyPress)
      document.addEventListener('keyup', onKeyUp)
    }

    return function cleanup(){
      if(!showUpload){
        document.removeEventListener('keypress', onKeyPress)
        document.removeEventListener('keyup', onKeyUp)
      }
    }
  }, [tracklist, currentTrack, trackPlaying, showUpload])


  async function countStream(trackid){
    try {
      const res = await fetch(STREAM_API, {
        headers: HEADERS,
        method: 'POST',
        body: JSON.stringify({
          trackid: trackid
        })
      })
      const txt = await res.text()
      console.log(txt)

      const updated = [...tracklist],
      index = updated.findIndex(track => track.trackid === trackid)
      if(index > -1){
        updated[index].streams += 1
        setTracklist(updated)
      }

    } catch (error) {
      console.log(error)
    }
  }

  async function playTrack(track, countStream = true, tryCast = false){
    
    setCurrentTrack(track)
    setOverlayTrack(track)

    // move title if it overflows
    document.getElementById("Test").innerHTML = track.title
    const titleWidth = document.getElementById("Test").clientWidth + 1,
    elementWidth = window.innerWidth*.4

    if(titleElement.current){
      if(titleWidth > elementWidth){
        titleElement.current.classList.add("move_left")
      }else{
        titleElement.current.classList.remove("move_left")
      }
    }
    

    try {
      if(trackPlaying){
        await audioRef.current.pause()
      }

      // track changed
      if(!lastTrack.current || (lastTrack.current && lastTrack.current.trackid !== track.trackid)){
        setTrackLoading(true)
        const res = await fetch(track.src)
        const blob = await res.blob()
        audioRef.current.src = null
        audioRef.current.src = URL.createObjectURL(blob)
        setTimeout(_ => {
          setTrackLoading(false)
        }, 500)
        audioRef.current.playbackRate = playBackRate
        document.getElementById("root").style.animationDuration = `${3 / playBackRate}s`


        streamCountRef.current = {
          started: countStream? performance.now() : performance.now() + (5*60*1000),
          counter: countStream? 0 : 35
        }
        
        if(loadedTimeRef.current){
          if(loadedTimeRef.current.id !== track.trackid){
            audioRef.current.currentTime = 0
          }else{
            audioRef.current.currentTime = loadedTimeRef.current.time
          }
          loadedTimeRef.current = null
        }
        

        if(coverRef.current){
          coverRef.current.style.transition = "none"
          coverRef.current.style.transform = "rotate(0deg)"
          setTimeout(() => {
            coverRef.current.style.transition =  chromecast.connected? "all 1.25s linear" : ""
          }, 50)
        }
      }

      setCurrentTrack(track)
      setOverlayTrack(track)
    
      document.title = `Jose Sanchez - ${track.title}`
      document.querySelectorAll("link[rel='apple-touch-icon']")[0].href = /^https?:/.test(track.image)? track.image : `https://josesanchez.simae.dev${track.image || '/images/placeholder.png'}`


      lastTrack.current = track

      if(chromecast.connected || tryCast){
        if(!reconnectToChromecastSession){
          // setTrackPlaying called when chromecast triggers play event
          await chromecast.cast(track.src, {
            src: track.src,
            title: track.title,
            description: "Jose Sanchez",
            poster: `https://josesanchez.simae.dev${track.image}` || "https://josesanchez.simae.dev/images/placeholder.png"
          })
        }else{
          setTrackPlaying(true)
        }
        reconnectToChromecastSession = false
        // chromecast.cast(track.src)
      }else{
          await audioRef.current.play()
          setTrackPlaying(true)
      }
      document.title = `Jose Sanchez - ${track.title}`
    } catch (error) {
      if(error.name !== "NotAllowedError"){
        showErrorMsg()
      }
    }
    userInitiated.current = false
  }

  function randomInt(min, max){
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  function addToQueue(track){
    queueRef.current.push(track)
    setShowOverlay(false)
  }

  function autoPlay(track, enabled){
    if(enabled){
      if(track){
        if(queueRef.current.length > 0){
          // debugger
          const queue = queueRef.current
          const firstTrack = queue.splice(0,1)[0]
          queueRef.current = queue
          if(firstTrack.trackid === lastTrack.current.trackid){
            audioRef.current.currentTime = 0
            try {
              audioRef.current.play()
            } catch (error) {
              
            }
          }else{
            playTrack(firstTrack)
          }
        }
        else if(shuffleRef.current){
          if(historyRef.current.length < tracklist.length){
            let random = randomInt(0,tracklist.length-1)
            while(historyRef.current.indexOf(random) !== -1){
              random = randomInt(0,tracklist.length-1)
            }
            historyRef.current.push(random)
            playTrack(tracklist[random])
          }else{
            const nextTrackIndex = historyRef.current[0]
            historyRef.current = [nextTrackIndex]
            playTrack(tracklist[nextTrackIndex])
          }
        }else{
          const index = tracklist.findIndex(el => el.trackid === track.trackid)
          if(index > -1 && index < tracklist.length-1){
            playTrack(tracklist[index+1])
          }
        }
      }else{
        playTrack(tracklist[0])
      }
    }
  }

  useEffect(() => {
    // const track = window.location.href.substr(window.location.href.lastIndexOf("/")+1)
    if(tracklist.length > 0){
      const sub = window.location.href.substr(window.location.href.lastIndexOf("/")+1)
      const _match = sub.match(/([trackid:]+):([0-9a-z]+)/i)
      if(_match && _match[2]){
        const trackid = _match[2]
        for(let i=0;i<tracklist.length;i++){
          if(trackid === tracklist[i].trackid){
            window.history.pushState({}, "", "/")
            isInitial.current = true
            setCurrentTrack(tracklist[i])
            setShowTrackOverlay(true)
            return
          }
        }
      }
  
      if(!hasLoaded){
        loadStatus()
        window.history.pushState('forward', null, '/')
      }
    }

  }, [tracklist])


  function saveCurrentStatus(track){
    if(audioRef.current){
      const obj = {
        trackid: track,
        time: audioRef.current.currentTime,
        timestamp: Date.now()
      }
      localStorage.setItem('currentStatus', JSON.stringify(obj))
    }
  }

  function loadStatus(){
    let data = localStorage.getItem('currentStatus')
    if(data){
      try {
        const json = JSON.parse(data)
        if(json.trackid){
          const index = tracklist.findIndex(el => el.trackid === json.trackid)
          loadedTimeRef.current = {
            id: tracklist[index].trackid,
            time: json.time
          }
          if(json.timestamp && (Date.now() - json.timestamp < (1000*10))){
            reconnectToChromecastSession = chromecast.state === "playing"
            playTrack(tracklist[index], json.time < 30)
          }else{
            setCurrentTrack(tracklist[index])
          }
        }
      } catch (error) {
        
      }finally{
        setTimeout(_ => {
          setHasLoaded(true)
        }, 500)
      }
    }
  }

  useEffect(() => {
    const useragent = navigator.userAgent.toLowerCase()
    if(useragent.indexOf('android') > -1){
      function onPopState(e){
        if(showOverlay){
          window.history.pushState('forward', null, '/')
          setShowOverlay(false)
        }else if(showTrackOverlay){
          window.history.pushState('forward', null, '/')
          setShowTrackOverlay(false)
        }else if(showInfoOverlay){
          window.history.pushState('forward', null, '/')
          setShowInfoOverlay(false)
        }else if(showQueue){
          window.history.pushState('forward', null, '/')
          setShowQueue(false)
        }
        return false
      }
      if(window.history && window.history.pushState){
        window.addEventListener('popstate', onPopState)
      }
  
      return function cleanup(){
        window.removeEventListener('popstate', onPopState)
      }
    }
  })

  useEffect(() => {
    if(hasLoaded){
      showLoadError.current = true
    }
  }, [hasLoaded])

  useEffect(() => {
    if(currentTrack){
      // audioRef.current.pause()
      // async function _play(){
      //   try {
      //     if(!trackPlaying && !audioRef.current.paused && audioRef.current.readyState >= 2){
      //       setTrackPlaying(true)
      //     }
      //   } catch (error) {
      //     showErrorMsg()
      //   }
      // }

      // _play()
      if(loadedTimeRef.current){
        if(loadedTimeRef.current.id === currentTrack.trackid){
          audioRef.current.currentTime = loadedTimeRef.current.time
        }
      }
      if(progressRef.current){
        progressRef.current.style.width = `${(audioRef.current.currentTime/currentTrack.duration)*100}%`
      }
    }
    isInitial.current = false
    
  }, [currentTrack])

  async function showErrorMsg(){
    if(isInitial.current) return
    if(!showLoadError.current) return
    setShowError(true)
    if(!audioRef.current.paused){
      try {
        // await audioRef.current.pause()
      } catch (error) {
        
      }
      setTrackPlaying(false)
    }
    if(timeoutRef.current){
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(_ => {
      setShowError(false)
    }, 3000)
  }

  useEffect(() => {
    function onTimeupdate(x, j){
      if(progressRef.current){
        progressRef.current.style.width = `${(audioRef.current.currentTime/audioRef.current.duration)*100}%`
      }
      if(rangeRef.current){
        rangeRef.current.value = audioRef.current.currentTime
        timeRef.current.innerHTML = formatTime(audioRef.current.currentTime)
      }
      if(coverRef.current && showSpinCover){
        coverRef.current.style.transform = `rotate(${(audioRef.current.currentTime/audioRef.current.duration)*(audioRef.current.duration*60)*playBackRate}deg)`
      }
      saveCurrentStatus(lastTrack.current?.trackid)

      if(streamCountRef.current.counter === 30){
        if(performance.now() - streamCountRef.current.started >= 30000){
          countStream(lastTrack.current?.trackid)
        }else{
          streamCountRef.current.counter = 15
        }
      }
      streamCountRef.current.counter += 1
    }

    function onEnd(){
      console.log("ended")
      setTrackPlaying(false)
      autoPlay(lastTrack.current, autoplayRef.current)
    }

    function onError(){
      showLoadError.current = true
      setTrackPlaying(false)
      // currentTrack(null)
      lastTrack.current = null
      showErrorMsg()
    }

    // for external controls (via notification)
    function onPause(){
      setTrackPlaying(false)
    }
    
    function onPlay(){
      setTrackPlaying(true)
    }

    if(audioRef.current){
      audioRef.current.addEventListener("timeupdate", onTimeupdate)
      audioRef.current.addEventListener("pause", onPause)
      audioRef.current.addEventListener("play", onPlay)
      audioRef.current.addEventListener("ended", onEnd)
      audioRef.current.addEventListener("error", onError)
    }

    return function cleanup(){
      if(audioRef.current){
        audioRef.current.removeEventListener("timeupdate", onTimeupdate)
        audioRef.current.removeEventListener("pause", onPause)
        audioRef.current.removeEventListener("play", onPlay)
        audioRef.current.removeEventListener("ended", onEnd)
        audioRef.current.removeEventListener("error", onError)
        clearTimeout(timeoutRef.current)
      }
    }
  }, [tracklist, playBackRate, showSpinCover])

  useEffect(() => {
    if(trackPlaying){
      document.getElementById("root").style.animationPlayState = "running"
      document.getElementById("root").style.animationDuration = `${3 / playBackRate}s`
    }else{
      document.getElementById("root").style.animation = "none"
      setTimeout(() => {
        document.getElementById("root").style.animation = ""
      }, 100)
    }
  }, [trackPlaying, playBackRate])

  function formatTime(seconds){
    if(!seconds) return "00:00"

    let minutes = Math.floor(seconds/60)
    seconds = Math.floor(seconds - minutes*60)
    return `${minutes < 10? "0":""}${minutes}:${seconds < 10? "0":""}${seconds}`
  }

  function formatNumber(number){
    if(!number){
      number = 0
    }
    return new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 8 }).format(number)
  }

  function onRangeChange(e){
    console.log("change", e.target.value)
    if(chromecast.connected){
      // does not work with audio atm
      // chromecast.seek(parseInt(e.target.value))
      
    }else{
      audioRef.current.currentTime = e.target.value
    }
    rangeRef.current.value = e.target.value
  }

  function onChangeAutoplay(){
    autoplayRef.current = !autoplayRef.current
    setUseAutoplay(!useAutoPlay)
  }

  async function toggleTrack(){
    try {

      if(trackPlaying){
        if(chromecast.connected){
          chromecast.pause()
        }else{
          await audioRef.current.pause()
        }
        setTrackPlaying(false)
        // setCurrentTrack(null)
      }else{
        if(chromecast.connected){
          chromecast.play()
          setTrackPlaying(true)
        }else{
          await audioRef.current.pause()
          if(currentTrack){
            playTrack(currentTrack)
          }else{
            playTrack(tracklist[0])
          }
        }
      }
    } catch (error) {
      showErrorMsg()
    }
  }

  async function prev(){
    userInitiated.current = true
    await audioRef.current.pause()
    const index = tracklist.findIndex(el => el.trackid === currentTrack.trackid)
    if(index > 0){
      playTrack(tracklist[index-1])
    }else if(index === 0){
      playTrack(tracklist[tracklist.length-1])
    }
  }

  async function next(){
    userInitiated.current = true
    await audioRef.current.pause()
    if(shuffleRef.current || queueRef.current.length > 0){
      return autoPlay(lastTrack.current, true)
    }
    const index = tracklist.findIndex(el => el.trackid === currentTrack.trackid)
    if(index < tracklist.length-1){
      playTrack(tracklist[index+1])
    }else if(index === tracklist.length-1){
      playTrack(tracklist[0])
    }
  }


  useEffect(() => {
    if(showTrackOverlay){
      if(rangeRef.current){
        rangeRef.current.value = parseInt(audioRef.current.currentTime)
      }
    }
  }, [showTrackOverlay])

  function formatPlaybackRate(){
    return parseFloat(playBackRate).toFixed(playBackRate === 1.25? 2 : 1)
  }

  function switchPlayBackRate(){
    const current = playBackRate
    let newValue = 1
    switch (current) {
      case 1:
        newValue = 1.25 
        break;
      case 1.25:
        newValue = 1.5
        break;
      case 1.5:
        newValue = 2
        break;
      case 2:
        newValue = .5
        break;
      case .5:
        newValue = 1
        break;
      default:
          newValue = 1
        break;
    }
    audioRef.current.playbackRate = newValue
    setPlayBackRate(newValue)
  }

  return (
    <div className="App">
      <div className="bg" />
      <button onClick={_ => {
        setShowInfoOverlay(true)
      }} className="info_btn">?</button>
      {chromecast.available && <button onClick={_ => {
        userInitiated.current = true
        playTrack(currentTrack, true, true)
      }} className="info_btn" style={{ left: "1rem", right: "unset", width: "35px", height: "35px", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1rem" }}><FaChromecast /></button>}
      <div className="scrollable">
        <div className="header">
          <h1 className="title">{artist}</h1>
          <button className="btn_follow" onClick={async () => {
            try {
              if(hasSubscribed){
                setSubLoading(true)
                await unsubscribe()
              }else{
                setSubLoading(true)
                await run()
              }
              await checkSubscription()
            } catch (error) {
              
            }finally{
              setSubLoading(false)
            }
          }}>{subLoading? <Loader/> : hasSubscribed? "Unfollow" : "Follow"}</button>
        </div>
        <InfoBanner onClose={checkSubscription} currentTrack={currentTrack} />
        <button onClick={toggleTrack} className="btnPlayAll">
          {trackPlaying? <BsPauseFill size={32} /> : <BsPlayFill size={32} />}
        </button>
        {newReleases && newReleases.length > 0
        ?
        <div>
          <h4>Neuerscheinungen</h4>
          <ul className="track_list">
            {newReleases.map((track, i) => {
              
              const released = Math.floor((Date.now() - track.release)/(1000*60*60*24))

              return (<li key={i.toString()}>
                <div className="track_wrapper new_release">
                  <div onClick={_ => {
                    if(!trackPlaying){
                      userInitiated.current = true
                      playTrack(track)
                      setShowTrackOverlay(true)
                    }else{
                      if(currentTrack?.trackid === track.trackid){
                        setShowTrackOverlay(true)
                      }
                    }
                  }}>
                    <img alt="cover"  src={track.image || placeholderImage} className="img"/>
                    <span className="track_title">
                      {track.title} 
                      <small>{released <= 1? "Heute erschienen" : `Vor ${released} Tagen erschienen`} | Single | {formatTime(track.duration)}</small>
                    </span>
                  </div>
                </div>
              </li>)
            })}
          </ul>
        </div>
        :
        null
        }
        <div>
          <h4>Beliebt</h4>
          {tracklist.length === 0
          &&
          <div>
            {showReload
            &&
            <button className="btn_reload" onClick={() => window.location.reload()}>
              <AiOutlineReload size={30}/>
              <small>reload</small>
            </button>
            }
            <ul className="track_list loading">
              <li>
                <span className="img l1"></span>
                <span className="l2">
                  <span></span>
                  <span></span>
                </span>
              </li>
              <li>
                <span className="img l1"></span>
                <span className="l2">
                  <span></span>
                  <span></span>
                </span>
              </li>
              <li>
                <span className="img l1"></span>
                <span className="l2">
                  <span></span>
                  <span></span>
                </span>
              </li>
              
              
            </ul>  
          </div>}
          <ul className="track_list">
            {tracklist.map((track, i) => (i < 5 && !showAll) || showAll?
              <li key={i.toString()}>
              <div className="track_wrapper">
                <div>
                  <span className="track_nr">
                    {trackPlaying && track.trackid === currentTrack?.trackid
                    ?
                    <div className="play_animation">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    :
                    <span className="track_nr_visible">{track.trackid === currentTrack?.trackid && trackLoading? <AiOutlineLoading size={15} className="rotate" /> : i+1}</span>
                    }
                    <button onClick={async _ => {
                      try {
                        if(trackPlaying && track.trackid === currentTrack?.trackid){
                          await audioRef.current.pause()
                          setTrackPlaying(false)
                          // setCurrentTrack(null)
                        }else{
                          // await audioRef.current.pause()
                          userInitiated.current = true
                          playTrack(track)
                        }
                      } catch (error) {
                        
                      }
                    }} className="track_nr_hidden">{track.trackid === currentTrack?.trackid? trackLoading? <AiOutlineLoading size={15} className="rotate" /> : trackPlaying? <BsPauseFill /> : <BsPlayFill /> : <BsPlayFill />}</button>
                    
                    {/* <AiOutlineLoading className="rotate" /> : trackPlaying? <BsPauseFill /> : <BsPlayFill />}</button> */}
                  </span>
                  <div className="clickable" onClick={_ => {
                    if(!trackPlaying){
                      userInitiated.current = true
                      playTrack(track)
                    }
                  }}>
                    <span className="img" style={{position: 'relative'}}>
                      <AiOutlineLoading className="rotate" style={{ color: "lightgray", position: 'absolute', zIndex: -1, top: '40%', left: '35%', transform: 'translateX(-50%) translateY(-50%)' }} />
                      <img alt="cover" style={{margin: 0}} src={track.image || placeholderImage} className="img"/>
                    </span>
                    <span className={track.explicit? "track_title expl" : "track_title"}>
                      {track.title}
                      <small><span className="streams hidden">{formatNumber(track.streams)}</span><span className="track_duration">{formatTime(track.duration)}</span></small>
                    </span>
                  </div>
                </div>
                <button onClick={_ => {
                  setOverlayTrack(track)
                  setShowOverlay(true)
                }} className="track_ctx">...</button>
              </div>
            </li>
            :
            null
            )}
          </ul>
          {tracklist.length > 0 && <button onClick={_ => setShowAll(!showAll)} className="expand_tracklist">{showAll? "weniger anzeigen":"mehr anzeigen"}</button>}
        </div>
        <div>
          <h4>Information</h4>
          
          <div className="info">
            <p>
              Jose Sanchez (bürgerlich Joel Hugo Achim Marimon) ist ein Künstler und Comedian aus der Flowerstreet. Durch frühe Erfolge mit tiefgreifenden Balladen wie <i>Gin Tonic</i> und <i>Wodka Bull</i> konnte er sich eine stetig wachsende Fanbase erarbeiten. Nach einer kurzen kreativen Auszeit meldet sich der spanische Patient nun in neuer Aufmachung und mit neuen Tracks zurück an seine Fans!
              {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, */}
            </p>
          </div>
        </div>
      </div>
      {currentTrack && <div className="current_track">
          {chromecastConnected && <div className="chromecast">playing on: {window?.cjs?.device} <FaChromecast size={15} style={{ marginLeft: ".5rem" }}/>
                <button className="btn_disconnect" onClick={() => {
                  chromecast.disconnect()
                }}>disconnect</button>
          </div>}
          <div className="progress"><div ref={ref => progressRef.current = ref}></div></div>
          <div className="left" onClick={_ => {
            setShowTrackOverlay(true)
          }}>
            <img alt="cover"  src={currentTrack?.image || placeholderImage} className="img" alt=""/>
            {currentTrack? <div>
              <span className="current_track_title">
                <span ref={ref => titleElement.current = ref}>{currentTrack.title}</span>
              </span>
              <small className="current_track_art">{artist}</small>
            </div> : null}
          </div>
          <div className="left">

            <button className="current_track_btn" onClick={_ => setShowQueue(true)} disabled={queueRef.current.length === 0}><FaList size={20} /> </button>
            <button className="current_track_btn" onClick={toggleTrack}>{trackLoading? <AiOutlineLoading className="rotate" /> : trackPlaying? <BsPauseFill /> : <BsPlayFill />}</button>
          </div>
          
      </div>}
      <audio ref={ref => audioRef.current = ref}>
        {/* <source src={currentTrack?.src} type='audio/mp3' /> */}
        {/* <source src={`/tracks/${currentTrack?.filename}`} type='audio/mp3' /> */}
      </audio>

      {showError && <div className="error">
        <h3>Hoppla...</h3>
        <p>Beim laden ist ein Fehler aufgetreten. Versuche es erneut!</p>
      </div>}

      {showOverlay
      &&
      <Overlay style={{zIndex: 102}} onClose={_ => {
        setShowOverlay(false)
      }} image={overlayTrack?.image || placeholderImage} track={overlayTrack}> 
        <h3>{artist}</h3>
        <h3><small>{formatTime(overlayTrack?.duration)} | {overlayTrack? new Date(overlayTrack?.release).getFullYear() : ""}</small></h3>
        <h3><small><small><IoIosStats size={20} style={{ marginBottom: '-5px' }}/> {formatNumber(overlayTrack?.streams)} {overlayTrack?.streams === 1? 'stream' : 'streams'}</small></small></h3>
        <ul className="menu">
          <li>
            <a href="#" onClick={e => {addToQueue(overlayTrack); e.preventDefault()}}><FaList size={12}  style={{marginRight: ".5rem"}} /> Zur Warteschlange</a>
          </li>
          <li>
            <a href="#" onClick={e => {share(overlayTrack?.trackid); e.preventDefault()}}><FaShareAlt size={12}  style={{marginRight: ".5rem"}} /> Share</a>
          </li>
          <li>
            <a href={overlayTrack?.src} download={overlayTrack?.filename}><BsDownload size={14} style={{marginRight: ".5rem"}} /> Download</a>
          </li>
        </ul>
      </Overlay>
      }
      
      {showQueue
      &&
      <div className="overlay ctx">
        <button onClick={_ => {
          setShowQueue(false)
        }} className="close_overlay"><IoIosArrowDown size={32} /></button>
        <h2>Warteschlange</h2>
        {queueRef.current.length === 0 && <h3>Warteschlange leer</h3>}
        <ul className="track_list">
            {queueRef.current.map((track, i) => (
              <li key={i.toString()}>
              <div className="track_wrapper">
                <div>
                  <span className="track_nr">
                    {trackPlaying && track.trackid === currentTrack?.trackid
                    ?
                    <div className="play_animation">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    :
                    <span className="track_nr_visible">{i+1}</span>
                    }
                    <button onClick={async _ => {
                      const newQueue = queueRef.current.filter(el => el.trackid !== track.trackid)
                      queueRef.current = newQueue
                      forceUpdate()

                    }} className="track_nr_hidden" title="remove from queue"><BsTrash /></button>
                  </span>
                  <img alt="cover"  src={track.image || placeholderImage} className="img"/>
                  <span className="track_title">
                    {track.title}
                    <small>{formatTime(track.duration)}</small>
                  </span>
                </div>
               
              </div>
            </li>
            ))}
          </ul>
      </div>}
      
      {showInfoOverlay
      &&
      <div className="overlay">
        <button onClick={_ => {
          setShowInfoOverlay(false)
        }} className="close_overlay"><IoIosArrowDown size={32} /></button>
        <h2>Info</h2>
        <h3>{artist}</h3>
        <ul className="menu">
          <li>
            <a href="#" onClick={e => {share(""); e.preventDefault()}}><FaShareAlt size={12}  style={{marginRight: ".5rem"}} /> Share</a>
          </li>
          <li>
            {hasSubscribed
            ?
            <a href="#" onClick={async e => { await unsubscribe(); await checkSubscription(); e.preventDefault(); }}><FaBellSlash size={12}  style={{marginRight: ".5rem"}} /> Unsubscribe</a>
            :
            <a href="#" onClick={async e => { await run(); setHasSub(true); e.preventDefault()}}><FaBell size={12}  style={{marginRight: ".5rem"}} /> Subscribe</a>
            }
          </li>
          <li>
            <a href="#" onClick={(e) => {
              if(chromecast.available){
                userInitiated.current = true
                playTrack(currentTrack, true, true)
              }
              e.preventDefault()
            }}><FaChromecast size={12}  style={{marginRight: ".5rem"}} /> {chromecast.available? "Cast to "+(chromecast.device!=="Chromecast"?chromecast.device:"TV") : "No Device available"}</a>
          </li>
          <hr/>
          <li>
            <a href="mailto:hi@simae.dev"><FaEnvelope size={12}  style={{marginRight: ".5rem"}} /> hi@simae.dev</a>
          </li>
          <li>
            <a href="https://github.com/smm76" target="_blank" rel="noreferrer"><FaGithub size={12}  style={{marginRight: ".5rem"}}/> github.com/smm76</a>
          </li>
          <hr/>
          <li>
            <a href="#" onClick={e => {setShowUpload(true); e.preventDefault()}}><BsUpload size={12}  style={{marginRight: ".5rem"}} /> Upload</a>
          </li>
        </ul>
        <div className="bmab">
            <small>liked this app?</small>
            <br/>
            <a href="https://www.buymeacoffee.com/smm76" rel='noreferrer'>
                <img alt="cover"  src="https://img.buymeacoffee.com/button-api/?text=Buy me a beer&emoji=🍺&slug=smm76&button_colour=40DCA5&font_colour=ffffff&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00" />
            </a>
        </div>
      </div>}

      {showTrackOverlay && currentTrack
      &&
      <Overlay onClose={_ => {
        setShowTrackOverlay(false)
      }}>
        <button onClick={_ => {
          setOverlayTrack(currentTrack)
          setShowOverlay(true)
        }} className="track_ctx">...</button>
        <div className="toggle">
          <label className="switch">
            <input type="checkbox" checked={useAutoPlay} onChange={onChangeAutoplay}/>
            <span className="slider round"></span>
          </label>
          <label>autoplay</label>
        </div>
        <div className="_streams" style={showSpinCover? null : { marginBottom: 0 }}>
          <div>
            <span><IoIosStats size={30} style={{marginBottom: '-5px'}} /> {formatNumber(currentTrack?.streams)}</span>
          </div>
          <div>
            <span>
              {chromecast.available && !chromecast.connected && <a href="#" style={{ marginRight: "1rem" }} onClick={e => {
                userInitiated.current = true
                playTrack(currentTrack, true, true)
                e.preventDefault()}}><FaChromecast size={25} /></a>}
              <a href="#" onClick={e => {share(currentTrack?.trackid);  e.preventDefault()}}><FaShareAlt size={25} /></a>
            </span>
          </div>
        </div>
        <div ref={ref => coverRef.current = ref} onClick={_ => setShowSpinCover(!showSpinCover)} className={showSpinCover? "img_container spin" : ""} style={showSpinCover? { transform: `rotate(${(audioRef.current.currentTime/audioRef.current.duration)*(audioRef.current.duration*60)*playBackRate}deg)`, transition: chromecast.connected? "all 1.25s linear" : "" } : { cursor: "pointer" }}>
          <div className="inner"></div>
          <img alt="cover"  src={currentTrack?.image || placeholderImage} className="xlg_img" />
        </div>
        <h2>{currentTrack?.title}</h2>
        <h3>{artist}</h3>
        <input onChange={onRangeChange} ref={ref => rangeRef.current = ref} type="range" min={0} max={currentTrack.duration} />
        <span className="time"><label ref={ref => timeRef.current = ref}>{formatTime(audioRef.current.currentTime)}</label>/{formatTime(currentTrack.duration)}</span>
        <div className="controls">
          <button onClick={prev}><IoIosArrowBack /></button>
          <button onClick={toggleTrack}>{trackPlaying? <BsPauseFill size={32} /> : <BsPlayFill size={32} />}</button>
          <button onClick={next}><IoIosArrowForward /></button>
        </div>
        <div className="settings">
          <button onClick={_ => {
            shuffleRef.current = !shuffleRef.current
            setUseShuffle(!useShuffle)
          }} className="playbackRate"><IoIosShuffle style={useShuffle? { color: "#1db954" } : null}  size={32} /> </button>
          <button onClick={_ => {
            audioRef.current.loop = !loopAudio
            setLoopAudio(!loopAudio)
          }} className="playbackRate"><IoIosRepeat style={loopAudio? { color: "#1db954" } : null} size={32} /> </button>
          <button style={playBackRate !== 1? { color: "#1db954" } : null} onClick={switchPlayBackRate} className="playbackRate">x {formatPlaybackRate()}</button>
        </div>
        {chromecastConnected && <div className="chromecast">playing on: {window?.cjs?.device} <FaChromecast size={15} style={{ marginLeft: ".5rem" }}/>
              <button className="btn_disconnect" onClick={() => {
                chromecast.disconnect()
              }}>disconnect</button>
        </div>}
      </Overlay>}

      {showUpload
      &&
      <Upload onClose={_ => {
        setShowUpload(false)
      }} />
      }

      <div id="Test"></div>

    </div>
  );
}

export default App;
