import { useEffect } from 'react'
import { IoIosArrowDown } from 'react-icons/io'

function Overlay(props){

    const { onClose, image, track, children, style } = props

    useEffect(() => {
        document.body.style.overflowY = "hidden"
        return function cleanup(){
            document.body.style.overflowY = "scroll"
        }
    }, [])

    return (
        <div className="overlay ctx" style={style}>
            <div className="overlay_inner">
                <button onClick={onClose} className="close_overlay"><IoIosArrowDown size={32} /></button>
                {image && <img src={image} className="lg_img" />}
                {track?.title && <h2>{track.title}</h2>}
                {children}
            </div>
        </div>
    )
}

export default Overlay